/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import '../../styles/main/main.css';
import {
  BsChevronDown, BsChevronUp
} from 'react-icons/all';
// import DonateModal from '../DonateModal';
import api from '../../api/api';
import MultiSelect from './MultiSelect';
import Select from './Select';
import SearchIcon from './SearchIcon';
import Episode from './Episode';
import Pagination from './Pagination';

const PAGE_SIZE = 10;

const Main = () => {
  // eslint-disable-next-line no-unused-vars
  const [donateModal, setDonateModal] = useState(false);
  const [stateValue, setStateValue] = useState([]);
  const [countyValue, setCountyValue] = useState([]);
  const [dateValue, setDateValue] = useState('');
  const [expandEvents, setExpandEvents] = useState(false);
  const [expandGoogle, setExpandGoogle] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [relevantUrls, setRelevantUrls] = useState([]);
  const [googleUrls, setGoogleUrls] = useState([]);
  const [fatalities, setFatalities] = useState(0);
  const [injuries, setInjuries] = useState(0);
  const [damage, setDamage] = useState(0);

  const [relevantPage, setRelevantPage] = useState(1);
  const [googlePage, setGooglePage] = useState(1);
  const [filterType, setFilterType] = useState('location-date');
  const [keywords, setKeywords] = useState('');
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 'normal',
      color: '#101D36',
      textAlign: 'left',
      paddingLeft: 20,
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      fontSize: 18,
      border: 'none',
      fontWeight: 400,
      height: 20,
      textAlign: 'left',
      opacity: state.hasValue ? 1 : 0.3
    }),
    input: (provided) => ({
      ...provided,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 600,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 0,
      color: '#101D36'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 400,
      color: '#101D36',
      paddingLeft: 0,
      paddingRight: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      padding: 5,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 400,
      color: '#101D36',
      textAlign: 'left',
      paddingLeft: 0,
      paddingRight: 0,
      opacity: state.hasValue ? 1 : 0.3,
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      width: 250
    }),
    menuList: (provided) => ({
      ...provided,
      border: '1px solid #101D36',
      borderRadius: 5,
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: '#101D36',
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'left',
      paddingLeft: '20px'
    })
  };

  const keywordCustomStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 'normal',
      color: '#101D36',
      textAlign: 'left',
      paddingLeft: 20,
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
      fontSize: 18,
      paddingLeft: 20,
      paddingRight: 20,
      border: '1.2px solid #000',
      fontWeight: 400,
      height: 50,
      textAlign: 'left',
      opacity: 1,
    }),
    input: (provided) => ({
      ...provided,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 600,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 0,
      color: '#101D36'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 400,
      color: '#101D36',
      paddingLeft: 0,
      paddingRight: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      padding: 5,
      fontSize: window.innerWidth > 768 ? 20 : 16,
      fontWeight: 400,
      color: '#101D36',
      textAlign: 'left',
      paddingLeft: 0,
      paddingRight: 0,
      opacity: state.hasValue ? 1 : 0.3,
    }),
    dropdownIndicator: () => ({
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      width: 250
    }),
    menuList: (provided) => ({
      ...provided,
      border: '1px solid #101D36',
      borderRadius: 5,
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: '#101D36',
      fontWeight: 'bold',
      fontSize: window.innerWidth > 768 ? 20 : 16,
      textAlign: 'left',
      paddingLeft: '20px'
    })
  };

  const clearFilters = () => {
    setCountyValue([]);
    setStateValue([]);
    setDateValue('');
  };

  const resetSearch = () => {
    clearFilters();
    setKeywords('');
    setIsSearchMode(false);
    setIsFiltersVisible(false);
    setExpandEvents(false);
  };

  useEffect(() => {
    resetSearch();
  }, [filterType]);

  useEffect(async () => {
    if (stateValue.length === 0 && countyValue.length > 0) return;

    const payload = {
      state: stateValue,
      county: countyValue
    };
    const { data } = await api.events.getDate(payload);
    const byYear = _.groupBy(data, (d) => d.BEGIN_YEARMONTH.slice(0, 4));
    const dates = Object.entries(byYear).map(([year, options]) => ({
      label: year,
      options: options.map((d) => {
        const value = new Date(
          Number(d.BEGIN_YEARMONTH.slice(0, 4)),
          d.BEGIN_YEARMONTH.slice(4) - 1,
          d.BEGIN_DAY
        );
        return {
          label: value.toLocaleDateString('en-US'),
          value: value.toLocaleDateString('en-US'),
        };
      })
    }));

    setDateOptions(_.orderBy(dates, 'label', 'desc'));
  }, [stateValue, countyValue]);

  const doSearch = async ({
    state, county, date, kwd
  }) => {
    setIsSearchMode(true);
    const { data } = await api.events.getEvents({
      state: state || [],
      county: county || [],
      date: date || '',
      keywords: kwd || '',
    });

    const {
      episodes: dataEpisodes, totalInjuries, totalDeaths, totalDamage,
      relevantUrls: dataRelevantUrls, googleUrls: dataGoogleUrls,
    } = data;
    setEpisodes(dataEpisodes);
    setInjuries(totalInjuries);
    setFatalities(totalDeaths);
    setDamage(totalDamage);
    setRelevantUrls(dataRelevantUrls);
    setGoogleUrls(dataGoogleUrls);
    setRelevantPage(1);
    setGooglePage(1);
  };

  const formattedEpisodes = useMemo(
    () => {
      const grouped = _.groupBy(episodes, (ep) => ep.EPISODE_NARRATIVE.toLowerCase());
      return Object.values(grouped).map((val) => ({
        text: val[0].EPISODE_NARRATIVE,
        events: _.uniqBy(val, (ev) => ev.EVENT_NARRATIVE.toLowerCase()).map((ev) => ev.EVENT_NARRATIVE)
      }));
    },
    [episodes]
  );

  useEffect(async () => {
    const { data } = await api.events.getCounty({ state: stateValue });
    const county = data.map((c) => ({
      value: c.CZ_NAME,
      label: c.CZ_NAME
    }));
    setCountyOptions(county);
  }, [stateValue]);

  useEffect(async () => {
    const { data } = await api.events.getAllStates();
    const states = data.map((s) => ({
      value: s.STATE,
      label: s.STATE
    }));
    setStateOptions(states);
  }, []);

  useEffect(() => {
    if (!isFiltersVisible) return;
    doSearch({
      state: stateValue,
      county: countyValue,
      date: dateValue,
      kwd: keywords
    });
  }, [stateValue, countyValue, dateValue]);

  const hasActiveFilters = stateValue.length > 0 || countyValue.length > 0 || Boolean(dateValue);

  return (
    <div className="main">
      <div className="main-container">
        <img src="/main-background-new.jpg" className="main-background" alt="background" />
        <div className="main-header">
          <span className="main-header-title">Flood Finder 360</span>
          <span className="main-header-description">
            AI-Enhanced retrieval of information about past flood events.
          </span>
        </div>
        <div className="search-container">
          <div className="main-selectors-container">
            <button type="button" className="reset-search" onClick={resetSearch}>Reset Search</button>
            <div className="search-type-tabs">
              <button
                type="button"
                className={`search-type-tab ${filterType === 'location-date' && 'active'}`}
                onClick={() => setFilterType('location-date')}
              >
                Search by location
              </button>
              <button
                type="button"
                className={`search-type-tab ${filterType === 'keyword' && 'active'}`}
                onClick={() => setFilterType('keyword')}
              >
                Search by Keyword
              </button>
            </div>
            <div className="main-selectors">
              { filterType === 'keyword' ? (
                <>
                  <input
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    type="text"
                    placeholder="Search information by keywords..."
                    className="keyword-selector"
                  />
                  { isSearchMode && (
                    <>
                      { !isFiltersVisible && <div className="separator" /> }
                      { isFiltersVisible && <div className="separator d-md-none" /> }
                      <button
                        type="button"
                        className={`add-filter-btn ${isFiltersVisible && 'active'}`}
                        onClick={() => {
                          if (isFiltersVisible) {
                            setIsFiltersVisible(false);
                            clearFilters();
                            doSearch({
                              kwd: keywords,
                            });
                          } else {
                            setIsFiltersVisible(!isFiltersVisible);
                          }
                        }}
                      >
                        Filter
                        { isFiltersVisible && <span>&times;</span> }
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="selector-container">
                    <span className={stateValue.length > 0 ? 'active' : ''}>State</span>
                    <Select
                      options={stateOptions}
                      styles={customStyles}
                      placeholder="Select State"
                      value={stateValue[0] ? { value: stateValue[0], label: stateValue[0] } : null}
                      onChange={(option) => setStateValue([option.value])}
                    />
                  </div>
                  <div className="separator" />
                  <div className="selector-container">
                    <span className={countyValue.length > 0 ? 'active' : ''}>County</span>
                    <Select
                      options={countyOptions}
                      styles={customStyles}
                      placeholder="Select County"
                      noOptionsMessage={() => (stateValue.length > 0 ? undefined : 'Select state first')}
                      value={countyValue[0] ? { value: countyValue[0], label: countyValue[0] } : null}
                      onChange={(option) => setCountyValue([option.value])}
                    />
                  </div>
                  <div className="separator" />
                  <div className="selector-container">
                    <span className={dateValue !== '' ? 'active' : ''}>Event Date</span>
                    <Select
                      options={dateOptions}
                      styles={customStyles}
                      placeholder="Select Date"
                      noOptionsMessage={() => (countyValue.length > 0 ? undefined : 'Select county first')}
                      value={dateValue ? { value: dateValue, label: dateValue } : null}
                      onChange={(option) => setDateValue(option.value)}
                    />
                  </div>
                </>
              )}

              <button
                onClick={() => doSearch({
                  state: stateValue,
                  county: countyValue,
                  date: dateValue,
                  kwd: keywords
                })}
                type="button"
                className="search-button"
              >
                <SearchIcon />
                Search
              </button>
            </div>
          </div>
        </div>
        { (isSearchMode || (hasActiveFilters && filterType === 'keyword') || isFiltersVisible) && (
          <div className="search-results-container">
            { hasActiveFilters && filterType === 'keyword' && (
              <div className="active-keyword-filters">
                <div className="active-keyword-filters-header">
                  <h3>Active Filters</h3>
                  <button type="button" onClick={clearFilters}>
                    Clear Filters
                  </button>
                </div>
                { stateValue.length > 0 && (
                  <div className="filters-row">
                    State:
                    {stateValue.map((state) => (
                      <span className="chip">
                        {state}
                        <button type="button" onClick={() => setStateValue(stateValue.filter((s) => s !== state))}>
                          &times;
                        </button>
                      </span>
                    ))}
                  </div>
                )}
                { countyValue.length > 0 && (
                  <div className="filters-row">
                    County:
                    {countyValue.map((county) => (
                      <span className="chip">
                        {county}
                        <button type="button" onClick={() => setCountyValue(countyValue.filter((c) => c !== county))}>
                          &times;
                        </button>
                      </span>
                    ))}
                  </div>
                )}
                { Boolean(dateValue) && (
                  <div className="filters-row">
                    Date:
                    <span className="chip">
                      {dateValue}
                      <button type="button" onClick={() => setDateValue('')}>
                        &times;
                      </button>
                    </span>
                  </div>
                )}
              </div>
            )}
            { isFiltersVisible && (
              <div className="keyword-filters">
                <MultiSelect
                  options={stateOptions}
                  styles={keywordCustomStyles}
                  value={stateValue}
                  placeholder={({ isFocused }) => (isFocused ? (
                    <div className="filter-placeholder">
                      <SearchIcon color="#C2C2C2" />
                      Search state...
                    </div>
                  ) : 'State')}
                  onChange={setStateValue}
                />

                <MultiSelect
                  options={countyOptions}
                  styles={keywordCustomStyles}
                  placeholder={({ isFocused }) => (isFocused ? (
                    <div className="filter-placeholder">
                      <SearchIcon color="#C2C2C2" />
                      Search county...
                    </div>
                  ) : 'County')}
                  value={countyValue}
                  noOptionsMessage={() => (stateValue.length > 0 ? undefined : 'Select state first')}
                  onChange={setCountyValue}
                />

                <Select
                  options={dateOptions}
                  styles={keywordCustomStyles}
                  placeholder={({ isFocused }) => (isFocused ? (
                    <div className="filter-placeholder">
                      <SearchIcon color="#C2C2C2" />
                      Search date...
                    </div>
                  ) : 'Date')}
                  controlShouldRenderValue={false}
                  noOptionsMessage={() => (countyValue.length > 0 ? undefined : 'Select county first')}
                  onChange={(option) => setDateValue(option.value)}
                />
              </div>
            )}

            { isSearchMode && isFiltersVisible && (
              <div className="search-results-separator" />
            )}

            {isSearchMode && (
              <div className="search-results">
                <div className={`search-events ${expandEvents && 'expanded'}`}>
                  { filterType === 'location-date' && Boolean(countyValue[0] || stateValue[0] || dateValue) && (
                    <span className="search-events-subtitle">
                      Flood search results for
                      {' '}
                      { [countyValue[0] ? `${countyValue[0]} County` : '', stateValue[0], dateValue].filter(Boolean).join(', ') }
                      .
                    </span>
                  )}
                  <span className="search-events-title">
                    Storm Event Data, National Oceanic and Atmospheric Administration
                  </span>
                  {
                    formattedEpisodes.slice(0, expandEvents ? Infinity : 1).map((episode, idx) => (
                      <Episode
                        text={(episode.text.length <= 300 || expandEvents) ? episode.text : `${episode.text.slice(0, 300)}...`}
                        title={`Episode ${idx + 1}`}
                        events={expandEvents ? episode.events : []}
                      />
                    ))
                  }
                  { expandEvents && (
                    <div className="event-numbers">
                      <div className="event-number" title="Number of fatalities">
                        <span className="number-category">Number of fatalities:</span>
                        <span className="number-value">{fatalities}</span>
                      </div>
                      <div className="event-number" title="Number of injures">
                        <span className="number-category">Number of injuries:</span>
                        <span className="number-value">{injuries}</span>
                      </div>
                      <div className="event-number" title="Total damages">
                        <span className="number-category">Total damages:</span>
                        <span className="number-value">
                          $
                          {new Intl.NumberFormat('en-US').format(Math.round(damage))}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                { expandEvents ? (
                  <button
                    onClick={() => setExpandEvents(false)}
                    type="button"
                    className="expand-events"
                  >
                    Read Less
                    <BsChevronUp />
                  </button>
                ) : (
                  <button
                    onClick={() => setExpandEvents(true)}
                    type="button"
                    className="expand-events"
                  >
                    Read More
                    <BsChevronDown />
                  </button>
                )}
                <div className="results-container">
                  <div className="search-results-header">AI-Enhanced Google Search Results</div>
                  { relevantUrls.length > 0 ? (
                    <>
                      <Pagination totalItems={relevantUrls.length} page={relevantPage} setPage={setRelevantPage} />
                      {
                        relevantUrls
                          .slice((relevantPage - 1) * PAGE_SIZE, relevantPage * PAGE_SIZE)
                          .map((r) => (
                            <div key={r.id} className="result-item">
                              <img
                                src={r.imageUrl || '/logo-small.png'}
                                style={{ objectFit: r.imageUrl ? 'cover' : 'contain' }}
                                alt="result"
                              />
                              <div className="result-item-body">
                                <h3 style={{ wordBreak: r.title ? 'normal' : 'break-all' }}>{r.title || r.url}</h3>
                                { r.publishedAt && (
                                  <span>
                                    {`${new Date(r.publishedAt).toLocaleDateString('en-US', {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                    })}`}
                                  </span>
                                )}
                                <h4>{r.description}</h4>
                                <a href={r.url} target="_blank" rel="noreferrer">Read Full Article</a>
                              </div>
                            </div>
                          ))
                      }
                      <Pagination totalItems={relevantUrls.length} page={relevantPage} setPage={setRelevantPage} />
                    </>
                  ) : (
                    <p>Your search did not match any data records. We&apos;re updating our models continuously for better results. Thank you for your patience!</p>
                  )}
                </div>
                <div className="results-container">
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div className="search-results-header google" onClick={() => setExpandGoogle(!expandGoogle)}>
                    Native Google Search Results
                    {expandGoogle ? <BsChevronUp /> : <BsChevronDown />}
                  </div>
                  { expandGoogle && googleUrls.length > 0 && (
                    <>
                      <Pagination totalItems={googleUrls.length} page={googlePage} setPage={setGooglePage} />
                      { googleUrls
                        .slice((googlePage - 1) * PAGE_SIZE, googlePage * PAGE_SIZE)
                        .map((r) => (
                          <div key={r.id} className="google-result-item">
                            <span>{`PUBLISHED ON: ${r.publishedAt ? new Date(r.publishedAt).toLocaleDateString() : '-'}`}</span>
                            <a href={r.url} target="_blank" rel="noreferrer">{r.url}</a>
                          </div>
                        ))}
                      <Pagination totalItems={googleUrls.length} page={googlePage} setPage={setGooglePage} />
                    </>
                  )}
                  { expandGoogle && googleUrls.length === 0 && (
                    <p>Your search did not match any data records. We&apos;re updating our models continuously for better results. Thank you for your patience!</p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="main-footer">
        <div className="main-footer-content">
          {/* <span className="main-footer-description"> */}
          {/*  We would appreciate your support to keep Flood Finder online and updated. */}
          {/* </span> */}
          {/* <button type="button" className="footer-donate-button" onClick={() => setDonateModal(true)}>Donate</button> */}
        </div>
      </div>
      {/* { */}
      {/*  donateModal && <DonateModal closeModal={() => setDonateModal(false)} /> */}
      {/* } */}
    </div>
  );
};

export default Main;
