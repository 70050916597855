import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../styles/header.css';

const Header = (props) => {
  // eslint-disable-next-line react/prop-types,no-unused-vars
  const { setDonateModal } = props;
  return (
    <div className="app-header">
      <Link to="/">
        <div className="header-left">
          <img src="/logo.png" alt="logo" />
        </div>
      </Link>
      <div className="header-right">
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contacts">Contact Us</NavLink>
        {/* <button */}
        {/*  type="button" */}
        {/*  className="donate-button" */}
        {/*  onClick={() => setDonateModal(true)} */}
        {/* > */}
        {/*  Donate */}
        {/* </button> */}
      </div>
    </div>
  );
};

export default Header;
