import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronLeft, BsChevronRight } from 'react-icons/all';

const PAGE_SIZE = 10;

export default function Pagination({ page, setPage, totalItems }) {
  const totalPages = Math.max(1, Math.ceil(totalItems / PAGE_SIZE));

  return (
    <div className="pagination">
      <span>
        {(page - 1) * PAGE_SIZE + 1}
        -
        {Math.min(totalItems, page * PAGE_SIZE)}
        {' of '}
        {totalItems}
      </span>
      <BsChevronLeft onClick={() => setPage(Math.max(1, page - 1))} />
      <div className="page-selector">
        Page:
        {' '}
        <select value={page} onChange={(e) => setPage(e.target.value)}>
          { Array(totalPages).fill(null).map((_, idx) => <option>{idx + 1}</option>) }
        </select>
      </div>
      <BsChevronRight
        onClick={() => setPage(
          Math.min(totalPages, page + 1)
        )}
      />
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
};
