import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Header from './components/Header';
import Main from './components/main/Main';
import Contacts from './components/contacts/Contacts';
import About from './components/about/About';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Footer from './components/Footer';
// import DonateModal from './components/DonateModal';

// Define the main app
const App = () => {
  // const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [donateModal, setDonateModal] = useState(false);

  return (
    <Router>
      <div className="App">
        <div className="app-content">
          <Header setDonateModal={setDonateModal} />
          <div className="app-container">
            <div>
              <Route exact path="/" component={Main} />
              <Route exact path="/contacts" component={Contacts} />
              <Route exact path="/about" component={About} />
            </div>
            {/* { */}
            {/*  donateModal && <DonateModal closeModal={() => setDonateModal(false)} /> */}
            {/* } */}
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
