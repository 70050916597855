import React from 'react';
import '../../styles/about/about.css';

const About = () => (
  <div className="about-container">
    <div className="about-body">
      <div className="about-header">
        About Flood Finder 360
      </div>
      <div className="about-text">
        <p>
          Flood Finder 360 uses AI to enhance the accuracy of Google searches for flood events.
          This Beta release is limited to retrieving information on flash flood events that occurred
          in the United States from 2000 to present.
        </p>
        <p>
          Flood Finder 360 is based upon work supported by the National Science Foundation (NSF)
          under Award Number 1931301.
        </p>
      </div>
    </div>
    <img className="about-image" alt="hero" src="/about-hero.jpg" />
  </div>
);

export default About;
