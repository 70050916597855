import parse from 'html-react-parser';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsChevronDown, BsChevronUp } from 'react-icons/all';

export default function Episode({ title, text, events }) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="event-container">
      <button title={title} type="button" className="event-title" onClick={() => setIsExpanded(!isExpanded)}>
        {title}
        { isExpanded ? <BsChevronUp /> : <BsChevronDown />}
      </button>
      { isExpanded && (
        <div>
          <div className="event-text">{parse(text)}</div>
          { events.length > 0 && (
            <>
              <div className="event-title" title="Event Narrative">Event Narrative</div>
              <div className="event-text">
                <ul>
                  { events.map((event) => (
                    <li>{parse(event)}</li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

Episode.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.string).isRequired,
};
