import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import CheckboxOption from './CheckboxOption';

export default function MultiSelect({
  options, styles, value, onChange, placeholder, ...rest
}) {
  const selectValue = useMemo(
    () => value.map((v) => ({ value: v })),
    [value]
  );
  const selectOnChange = useCallback(
    (val) => onChange(val.map((o) => o.value)),
    [onChange]
  );
  return (
    <Select
      isMulti
      isClearable={false}
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option: CheckboxOption }}
      options={options}
      styles={styles}
      value={selectValue}
      placeholder={placeholder}
      onChange={selectOnChange}
      {...rest}
    />
  );
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.any,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

MultiSelect.defaultProps = {
  styles: null,
  placeholder: '',
};
