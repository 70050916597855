import ReactSelect from 'react-select';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Select({ placeholder, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <ReactSelect
      {...rest}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      placeholder={typeof placeholder === 'function' ? placeholder({ isFocused }) : placeholder}
    />
  );
}

Select.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Select.defaultProps = {
  placeholder: '',
};
