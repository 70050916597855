import React from 'react';

const Footer = () => (
  <div className="app-footer">
    <div className="footer-divider" />
    <span className="footer-description">© 2023 Flood Finder, LLC. All rights reserved.</span>
  </div>
);

export default Footer;
