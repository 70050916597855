import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

const Option = ({ isSelected, label, ...rest }) => (
  <div>
    <components.Option {...rest}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => null}
      />
      {' '}
      <label>{label}</label>
    </components.Option>
  </div>
);

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default Option;
