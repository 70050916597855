import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((c) => {
  c.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return c;
});

const api = {
  events: {
    getAllStates: () => instance.get('api/events/getAllStates'),
    getCounty: (data) => instance.post('api/events/getCounty', data),
    getDate: (data) => instance.post('api/events/getDate', data),
    getEvents: (data) => instance.post('api/events/getEvents', data),
  },
  contact: (data) => instance.post('api/contact', data),
  donate: (data) => instance.post('api/donate', data),
};

export default api;
