import React, { useState } from 'react';
import '../../styles/contacts/contacts.css';
import { ReactComponent as GeoIcon } from '../../public/images/geo.svg';
import api from '../../api/api';

const Contacts = () => {
  const [status, setStatus] = useState('ready');
  const onSubmit = (e) => {
    setStatus('ready');
    const data = {};
    const fd = new FormData(e.target);
    [...fd.keys()].forEach((key) => {
      data[key] = fd.get(key);
    });

    api.contact(data)
      .then(() => setStatus('success'))
      .catch(() => setStatus('error'));

    e.preventDefault();
    return false;
  };

  return (
    <div className="contacts-container">
      <div className="contacts-header">
        <span className="contacts-header-title">
          Interested in learning more?
          <br />
          We’d love to hear from you.
        </span>
      </div>
      <div className="contacts-body">
        <div className="contacts-form">
          <form onSubmit={onSubmit}>
            { status === 'success' && (
              <div className="alert alert-success">
                Thank you for contacting us, we will try to respond as soon as possible.
              </div>
            ) }
            { status === 'error' && (
              <div className="alert alert-danger">
                Some error occurred.
              </div>
            ) }
            <div className="row">
              <div className="col-12 col-md-6">
                <input type="text" placeholder="First Name*" required name="firstName" />
              </div>
              <div className="col-12 col-md-6">
                <input type="text" placeholder="Last Name" name="lastName" />
              </div>
            </div>
            <input type="email" placeholder="Email Address*" required name="email" />
            <div className="row">
              <div className="col-12 col-md-6">
                <input type="text" placeholder="Company*" required name="company" />
              </div>
              <div className="col-12 col-md-6">
                <input type="text" placeholder="Your Title*" required name="title" />
              </div>
            </div>
            <textarea placeholder="How can we help?" required name="message" />
            <button type="submit">Send Message</button>
          </form>
        </div>
        <div className="contacts-info">
          <div className="contacts-location">
            <GeoIcon />
            <span>
              Texas A&M University
              <br />
              3136 TAMU College Station, TX
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
