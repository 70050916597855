import React from 'react';
import PropTypes from 'prop-types';

export default function SearchIcon({ color }) {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.4375 15.875C12.5451 15.875 15.875 12.5451 15.875 8.4375C15.875 4.32988 12.5451 1 8.4375 1C4.32988 1 1 4.32988 1 8.4375C1 12.5451 4.32988 15.875 8.4375 15.875Z" stroke={color} strokeWidth="2" />
      <path d="M18 18L13.75 13.75" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

SearchIcon.propTypes = {
  color: PropTypes.string,
};

SearchIcon.defaultProps = {
  color: '#FFFDFF'
};
